import $ from './jquery-module';

import * as brand_colors from "@smartaira/smartaira-branding/dist/colors";

if(window.graph_colors) {
    window.portal_style = document.querySelector('meta[name="portal-style"]').content;
    if(portal_style === "bluerim") {
        graph_colors[0] = '#99b434';  // bluerim green
        graph_colors[1] = '#273646';  // bluerim blue
    } else {
        graph_colors[0] = brand_colors.smartaira_midnight_blue;
        graph_colors[1] = brand_colors.smartaira_orange;
    }
}
$(function(){
  // The old ruby Graph#GRAPH_COLORS_ARRAY consumed by rrdgraph
    window.portal_style = $("meta[name='portal-style']").attr("content");
    if(window.graph_colors) {
        if(portal_style === "bluerim") {
            graph_colors[0] = '#99b434';  // bluerim green
            graph_colors[1] = '#273646';  // bluerim blue
        } else {
            graph_colors[0] = brand_colors.smartaira_midnight_blue;
            graph_colors[1] = brand_colors.smartaira_orange;
        }
    }
});
