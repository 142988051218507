
// @ts-ignore
import * as _FormValidation from 'formvalidation';
// @ts-ignore
import Uikit from 'formvalidation/plugins/Uikit';
// @ts-ignore
_FormValidation.plugins.Uikit = Uikit;

// @ts-ignore
window.FormValidation = _FormValidation;

export const FormValidation = _FormValidation;
export default FormValidation;

