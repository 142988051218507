import * as _jquery from 'jquery';
if((<any>window).jQuery === undefined) {
    (<any>window).jQuery = _jquery;
    (<any>window).$ = _jquery;

    _jquery(function () {
        _jquery('.dropdown-toggle').dropdown();
    });
}
export const $:JQueryStatic = (<any>window).jQuery;
export const jQuery:JQueryStatic = (<any>window).jQuery;
import "bootstrap";

$(function(){
    $('.dropdown-toggle').dropdown();
    $('button[data-disable-with-spinner="true"], a[data-disable-with-spinner="true"]').on("click", function(){
        const me = $(this);
        me.addClass('disabled');
        me.find('.spinner-border').removeClass('hidden');
    })
});

export default jQuery;
