// JavaScript assets manifest for this custom portal.
//
//= require ./uuid_tiny
import './uuid_tiny';

// jQuery framework:
import 'es6-shim';
//= require ./es6-shim.min
//= require ./jquery-3.3.1.min
// require jquery
import './jquery-module';

//= require jquery_ujs
import "jquery-ujs";
//= require ./ua-parser.min
import './ua-parser';
//
// UIkit framework:
//= require ./uikit/uikit
import 'uikit';
//= require ./uikit/components/slideshow
import 'uikit/dist/js/components/slideshow';
//= require ./uikit/components/tooltip
import 'uikit/dist/js/components/tooltip';
//= require ./uikit/core/offcanvas
import 'uikit/dist/js/core/offcanvas';
//
// Masonry cascading grid layout library:
//= require ./masonry.pkgd.min
import 'masonry-layout/dist/masonry.pkgd';
//
// Graphing libraries:
// TODO: Fix these to use our own version
//= require jquery.fileDownload
import 'jquery-file-download';
//= require shared/d3.v3.min
import 'd3';
//= require shared/nv.d3.min
import 'nvd3';
//= require shared/charting

// TODO add this later after figuring out what it adds
// require shared/chartist.min
// TODO add this later
// require ./graphing

import * as charting from './charting';

//= require ./branding
import './branding';
//= require shared/dashboard
import * as dashboard from './dashboard'; // this does not appear to be an open source project
[charting,dashboard].forEach(imprt=>{
    Object.keys(imprt).forEach(key=>{
        // @ts-ignore
        window[key] = imprt[key];
    })
});
//
// Gauges
//= require shared/justgage.min
import 'justgage';
//= require shared/raphael-2.1.4.min
import 'raphael';
//
// Bootstrap
//= require ./popper.min
//= require ./bootstrap.min
// Datetime libraries
//= require ./moment/moment-with-locales.min
import './moment-module';
//= require ./tempusdominus/tempusdominus-bootstrap-4.min
import 'tempusdominus-bootstrap-4';
// TODO: BEGIN NEW PORTAL JAVASCRIPT LIBRARIES
//
// Datatables
// require ./datatables.min
//
// Offcanvas Navbar
// require ./offcanvas
//
// Graph overrides
// require ./graphs
//
// Time Sensitive Stuff
// require ./time
//
// TODO: END NEW PORTAL JAVASCRIPT LIBRARIES
//
// QR Code
//= require ./qrcode
//= require ./jquery.qrcode
import './jquery.qrcode';
//
// Dynamically pull in other libraries
// require ./particles
// require ./my_particles
//= require ./alerts
import './alerts';
//= require ./format.es5
import 'number-format.js/lib/format.es5';
//
// Form Validation
//= require ./form_validation/FormValidation.full.min
//= require ./form_validation/plugins/Uikit.min
import './formvalidation-module';

/*
requirejs.config({
    paths: {
        webassembly:"<%=javascript_url('./scanner/webassembly/a.out.js') %>?",
        asmscript:"<%=javascript_url('./scanner/asmscript/a.out.js') %>?",
        scanner:"<%=javascript_url('./scanner/scanner.js') %>?"
    }
});
*/

