//Setup mole browser detection: Partial credit: http://detectmobilebrowser.com/
export function userAgent() {
  return (navigator.userAgent || navigator.vendor || window.opera).toLowerCase();
}

export function isIos() { //has full support of features in iOS 4.0+, uses a new window to accomplish this.
  if (/ip(ad|hone|od)/.test(userAgent())) {
    return true;
  } else {
    return false;
  }
}

export function isAndroid() { //has full support of GET features in 4.0+ by using a new window. Non-GET is completely unsupported by the browser. See above for specifying a message.
  if (userAgent().indexOf('android') !== -1) {
    return true
  } else {
    return false;
  }
}

export function isOtherMobileBrowser() { //there is no way to reliably guess here so all other mobile devices will GET and POST to the current window.
  if (/avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|playbook|silk|iemobile|iris|kindle|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(userAgent) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|e\-|e\/|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(di|rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|xda(\-|2|g)|yas\-|your|zeto|zte\-/i.test(userAgent.substr(0, 4))) {
    return true
  } else {
    return false
  }
}


// reload the page with the specified parameter key(s) changed.  currently unused
export function insertParams(obj)
{
  var kvp = document.location.search.substr(1).split('&');

  for (var key in obj) {

    key = encodeURI(key); value = encodeURI(obj[key]);
    var i=kvp.length; var x; while(i--)
    {
        x = kvp[i].split('=');

        if (x[0]==key)
        {
            x[1] = value;
            kvp[i] = x.join('=');
            break;
        }
    }

    if(i<0) {kvp[kvp.length] = [key,value].join('=');}

  }

  //this will reload the page, it's likely better to store this until finished
  document.location.search = kvp.join('&');
}


// Stuff to do on page load
$(function() {

  // update the widget position indicators (visible while in edit mode)
  updateWidgetPositionIndicators();

  if (jQuery().tooltip) {
    // Initialize tooltip event listeners
    // Supplying a selector causes the listener to delegate to all tooltip links
    // rather than repeatedly re-adding tooltips every time a dialog is updated.
    $('body').tooltip({
      selector: '[data-toggle="tooltip"]'
    })

    // Allow for generation of links that go nowhere, allowing bootstrap tooltip
    // behavior even when we don't want to leave the current page.
    $(".no_href").click(function(e) {
      e.preventDefault();
    })

    // Add an event listener to body to remove stale tooltips but delegate to the
    // tooltip links.
    $('body').on({
      mouseleave: function() {
        $('.tooltip').remove();
      }
    }, '[data-toggle="tooltip"]');

  }



  /* widgets can only reference one dialog, graph, or report. when changing the
  selection of one category, reset all other categories to their prompt values.
  Delegate the event listener to the body element */
  $('body').on({
    change: function() {
      $('#add_widget_modal select').not(this).val('');
    }
  }, '#add_widget_modal select');
  $('body').on({
    change: function() {
      $(this).closest('.edit_widget_modal').find('select').not(this).val('');
    }
  }, '.edit_widget_modal select');

  // handle click events for moving widgets up
  $('body').on({
    click: function() {
      var widget = $(this).closest('.widget');
      $(widget).insertBefore($(widget).prevAll('.widget')[0]);
      updateWidgetPositionIndicators();
    }
  }, '.move_widget_up');

  // handle click events for moving widgets down
  $('body').on({
    click: function() {
      var widget = $(this).closest('.widget');
      $(widget).insertAfter($(widget).nextAll('.widget')[0]);
      updateWidgetPositionIndicators();
    }
  }, '.move_widget_down');

  // handle the click event for entering/exiting edit mode of a custom dashboard
  $('body').on({
    click: function() {
      if ($(this).data('enabled') == 'true') {
        // disable sorting
        sortableElement.sortable('disable');
        // re-enable text selection
        sortableElement.enableSelection();
        $('.widgets_container').removeClass('sorting_active');
        $(this).html('<span class="glyphicon glyphicon-move"></span> Edit Mode')
          .removeClass('edit_mode_active')
          .data('enabled', 'false');
        $('body').removeClass('edit_mode_active');
      } else {
        // enable sorting
        sortableElement.sortable('enable');
        // disable text selection while sorting
        sortableElement.disableSelection();
        $('.widgets_container').addClass('sorting_active');
        $(this).html('<span class="glyphicon glyphicon-move"></span> Leave Edit Mode')
          .addClass('edit_mode_active')
          .data('enabled', 'true');
        $('body').addClass('edit_mode_active');
      }
    }
  }, '#dashboard_edit_mode');

  // When hovering over a graphviz node, highlight the node and all connected
  // edges and nodes by adding the 'gv-hovered' CSS class to this node and all
  // other objects having any of the same classes as this node
  $('body').on({
    mouseenter: function() {
      var classList = $(this).attr('class').split(' ');
      $.each(classList, function(index, value) {
        if (value == 'node') {
          return true;
        }
        $("." + value).addClass('gv-hovered');
      })
    },
    mouseleave: function() {
      var classList = $(this).attr('class').split(' ');
      $.each(classList, function(index, value) {
        if (value == 'node') {
          return true;
        }
        $("." + value).removeClass('gv-hovered');
      })
    },
  }, '.node');

})

// Return true if we can create a TouchEvent
export function canDoTouchEvent() {
  try {
    document.createEvent("TouchEvent");
    return true;
  } catch (e) {
    return false;
  }
}

export var initFileCheckLoop = function(url, result_id) {
  // Check the status of the file generation
  $.ajax({
    url: url,
    success: function(data) {
      if (data.exists == true) {
        // Actually download the file
        if (isIos()) {
          // iOS is problematic with jquery.fileDownload?
          window.location.href = data.download_url;
        } else {
          // https://github.com/johnculviner/jquery.fileDownload/issues/63
          $.fileDownload(data.download_url);
        }

        $("#exportDashboardModal").modal('hide');
      } else {
        // Create a timeout to pause for the desired interval
        // before relaunching this function.
        var checkTimeout = setTimeout(function() {
          initFileCheckLoop(url, result_id);
        }, 3000)
      }
    },
    error: function(xhr, ajaxOptions, thrownError) {
      $('#' + result_id).html(thrownError);
    },
    timeout: 15000
  });

}

// Set the content of the position indicators, which are visible only
// while in edit mode.
export function updateWidgetPositionIndicators() {
  $(".position").each(function(index) {
    $(this).html(index + 1);
  })
}

// Make the dashboard widgets sortable using jQuery-UI's sortable library
export function makeWidgetsSortable(update_widget_order_url) {
  sortableElement = $(".sortable").sortable({
      cancel: "a,button",
      tolerance: "pointer",
      placeholder: "drop_target",
      update: function(event, ui) {
        var $items = $('.sortable > *');
        var position_list = {};
        $items.each(function(index) {
          var widget_id = $(this).data('widget-id');
          var position = index;
          position_list[widget_id] = position;
        })

        updateWidgetPositionIndicators();

        $.ajax(
          update_widget_order_url, {
            data: {
              widgets: position_list
            }
          }
        );
      }
    })
    // disable the sorting until the user enters edit mode
  sortableElement.sortable('disable');
}


// Global counter to increment after each dashboard update. Provided to the
// rails request layer as the AJAX iteration counter. Start with 1 and not zero,
// because we don't need to immediately update every dialog right after page
// load.
export var dashboardDialogUpdateIteration = 1;

// How frequently we request updates for dashboard dialogs
export var dashboardDialogRefreshInterval = 4500;

// Periodically reload an entire dashboard page to avoid memory bloat and other
// possible issues from nvd3 when a tab is inactive. This limit should be a
// number of iterations and not necessarily time, since each iteration is what
// contributes to the overflow issue.
export var maxDashboardDialogUpdateIterations = 500;

// Flag to indicate we've automatically initiated a dashboard refresh
export var dashboardRefreshInitiated = false;

// Initiate periodic AJAX updates to dashboard dialogs in the current view
export var initiate_dashboard_dialog_updates = function(dashboard_data_url) {
    // Include the current iteration count in the URL
    if (/\?/i.test(dashboard_data_url)) {
      var url = dashboard_data_url + '&iteration=' + dashboardDialogUpdateIteration
    } else {
      var url = dashboard_data_url + '?iteration=' + dashboardDialogUpdateIteration
    }

    // Request the admin/dialogs/dialog_data action, which will return a JSON
    // hash keyed by dialog names, with values of the dialog data/content. The
    // desired dialog names are included in the URL passed to this function.
    $.ajax(url).done(function(dashboard_data) {
      try {
        // Reload the dashboard if we've hit the max iteration count
        if (dashboardDialogUpdateIteration > maxDashboardDialogUpdateIterations) {
          dashboardRefreshInitiated = true;
          document.location.reload();
        }

        // Update dialog colors
        $.each(dashboard_data["colors"], function(id, color) {
          if (color == "none") {
            $("#" + id + "_dialog").removeClass('red yellow');
          } else {
            $("#" + id + "_dialog").removeClass('red yellow').addClass(color);
          }
          // Update the glyphicon
          if (color == 'red') {
            $("#" + id + "_color_reason > a > span").addClass('glyphicon-exclamation-sign').removeClass('glyphicon-info-sign');
          } else if (color == 'yellow') {
            $("#" + id + "_color_reason > a > span").addClass('glyphicon-info-sign').removeClass('glyphicon-exclamation-sign');
          }
        });

        // Update the tooltip text or hide the color reason icon
        $.each(dashboard_data["color_reasons"], function(id, color_reason) {
          if (color_reason == "none") {
            $("#" + id + "_color_reason").addClass('hidden');
          } else {
            $("#" + id + "_color_reason").removeClass('hidden');
            $("#" + id + "_color_reason")
            $("#" + id + "_color_reason > a").attr('title', color_reason)
              .tooltip('fixTitle');
          }
        });
        $.each(dashboard_data["color_reason_actions"], function(id, color_reason_action) {
          if (color_reason_action != "none") {
            $("#" + id + "_color_reason > a").attr('href', color_reason_action);
          }
        });

        // Update dialog gauges
        $.each(dashboard_data["gauges"], function(id, gauge) {
          if (typeof window[id] !== 'undefined') {
            window[id].refresh(gauge.value);
          }
        });

        // Update dialog pie charts
        $.each(dashboard_data["pie_charts"], function(id, pie_chart) {
          var svg = $("#" + id + "_svg");
          if (svg.length == 0) {
            return;
          }
          var the_side = svg.closest('.item');
          if (the_side.length > 0) {
            if (!the_side.hasClass('active') || the_side.hasClass('left') || the_side.hasClass('right')) {
              return;
            }
          }
          if (pie_chart["donut"] == true) {
            d3.select(svg.selector)
              .datum(pie_chart["data"])
              .transition().duration(350)
              .call(pie_charts[id]);
          } else {
            d3.select(svg.selector)
              .datum(pie_chart["data"])
              .transition().duration(350)
              .call(pie_charts[id]);;
          }
        });

        // Resize any charts (i.e., pie) with no data
        resizeNoDataCharts();

        // Update dialog progress bars
        $.each(dashboard_data["progress_bars"], function(id, bars_html) {
          $("tbody#" + id).html(bars_html);
        });

        // Update dialog tables
        $.each(dashboard_data["tables"], function(id, rows_html) {
          $("tbody#" + id).html(rows_html);
        });

        // Update dialog summary text
        $.each(dashboard_data["summaries"], function(id, summary_html) {
          $("#" + id + "_summary_text").html(summary_html);
        });

        // Update dialog graph link
        $.each(dashboard_data["graph_links"], function(id, link) {
          $("#" + id + "_graph_link").html(link);
        });

        // Update dialog details link
        $.each(dashboard_data["details_links"], function(id, link) {
          $("#" + id + "_details_link").html(link);
        });

        // Hide the "Device unreachable..." warning
        $("#dashboard_updates_stopped").hide();

        // Create a timeout to pause for the desired interval
        // before relaunching this function.
        var updateTimeout = setTimeout(function() {
          initiate_dashboard_dialog_updates(dashboard_data_url)
        }, dashboardDialogRefreshInterval)
      } catch (err) {
        console.log(err.message);
        //console.log(err.stack);
        // Create a timeout to pause for the desired interval
        // before relaunching this function.
        var updateTimeout = setTimeout(function() {
          initiate_dashboard_dialog_updates(dashboard_data_url)
        }, dashboardDialogRefreshInterval)
      } finally {
        // Initialize carousel event hooks
        if (canDoTouchEvent() == true) {
          $(".carousel").swiperight(function() {
            $(this).carousel('prev');
          });
          $(".carousel").swipeleft(function() {
            $(this).carousel('next');
          });
        }
        // Increment the update iteration counter
        dashboardDialogUpdateIteration++;
      }
    }).fail(function(XMLHttpRequest, textStatus, errorThrown) {
      // Display a "Device unreachable..." warning unless we initiated a refresh
      if (!dashboardRefreshInitiated) {
        $("#dashboard_updates_stopped").show();
      }

      // Create a timeout to pause for the desired interval
      // before relaunching this function.
      var updateTimeout = setTimeout(function() {
        initiate_dashboard_dialog_updates(dashboard_data_url)
      }, dashboardDialogRefreshInterval)

      // Increment the update iteration counter
      dashboardDialogUpdateIteration++;
    });


  } // End of initiate_dashboard_dialog_updates function

// Change the size of charts that get the "no Data" message
export function resizeNoDataCharts() {
  $(".nv-noData").parents("svg").css("min-height", 25).css("height", 25);
  $(".nv-pieChart").parents("svg").css("min-height", '200').css("height", '');

  var event = document.createEvent('Event');
  event.initEvent('resize', true, true);
  window.dispatchEvent(event);
}

// Global for pie chart objects, because we need to be able to access them
// within initiate_dashboard_dialog_updates().
export var pie_charts = {}

// Plot a pie or donut chart
export function plotPieDonutChart(chart_options) {
  nv.addGraph(function() {

    var pieChart = nv.models.pieChart();
    pieChart
      .x(function(d) {
        return d.label
      })
      .y(function(d) {
        return d.value
      })
      .showLabels(true)
      .labelThreshold(.1)
      .labelType(chart_options.labelType)
      .labelSunbeamLayout(false)
      .growOnHover(true)
      .margin({
        bottom: -10
      })
      .tooltip.contentGenerator(function(d) {
        var html = "<table>";
        if (d.data.display_text != undefined) {
          var displayText = d.data.display_text
        } else {
          var displayText = d.data.value
        }
        d.series.forEach(function(elem) {
          html += "<tr><td class='legend-color-guide'><div style='background-color: " + elem.color + ";'</div></td><td class='key'>" + elem.key + "</td><td class='value'>" + displayText + "</td></tr>";
        })
        html += "</table>";
        return html;
      })

    // Set the maximum number of characters shown in a series' name in the legend.
    // This is preferable to truncating series names in the controller as the tooltip
    // retains the full series name.
    pieChart.legend.maxKeyLength(10);

    var donutChart = nv.models.pieChart();
    donutChart
      .x(function(d) {
        return d.label
      })
      .y(function(d) {
        return d.value
      })
      .showLabels(true) //Display pie labels
      .labelThreshold(.1) //Configure the minimum slice size for labels to show up (default 0.02)
      .labelType(chart_options.labelType) //Configure what type of data to show in the label. Can be "key", "value" or "percent"
      .donut(true) //Turn on Donut mode. Makes pie chart look tasty!
      .donutRatio(0.35) //Configure how big you want the donut hole size to be.
      .growOnHover(true) //default true
      .margin({
        bottom: -10
      });

    if (chart_options.donut == "true") {
      pie_charts[chart_options.id] = donutChart;
      d3.select(chart_options.target)
        .datum(chart_options.data)
        .transition().duration(350)
        .call(donutChart);
    } else {
      pie_charts[chart_options.id] = pieChart;
      d3.select(chart_options.target)
        .datum(chart_options.data)
        .transition().duration(350)
        .call(pieChart);
    }

    resizeNoDataCharts();

    // function to run when the window is resized.  This can also be triggered
    // manually by executing window.dispatchEvent(new Event('resize'));
    nv.utils.windowResize(function() {
      var the_side = $(chart_options.target).closest('.item');
      if (the_side.length > 0) {
        // Is on a 2 sided dialog
        if (the_side.hasClass('active') && !the_side.hasClass('left') && !the_side.hasClass('right')) {
          // Side is currently displayed
          if (chart_options.donut == "true") {
            donutChart.update();
            return donutChart;
          } else {
            pieChart.update();
            return pieChart;
          }

        } // Side hidden.  Do nothing
      } else {
        // It's not on a 2 sided dialog
        if (chart_options.donut == "true") {
          donutChart.update();
          return donutChart;
        } else {
          pieChart.update();
          return pieChart;
        }
      }

    });

  });

}
